import BaseService from 'services/baseService';
import { REACT_APP_API_BASE_URL } from 'shared/config/config';
import { API_GATEWAY_URL } from 'shared/consts/consts';
import { UpdateOperationType } from 'shared/enum';
import { serialize } from 'shared/helpers';

const createBroadcastUrl = '/api/v1/broadcast/save/';
const createShopifyBroadcastUrl = '/api/v1/shopify/broadcast/save/';
const loadBroadCastUrl = '/api/v1/broadcast/history/';
const deleteBroadcastUrl = '/api/v1/broadcast/delete/';
const getBroadCastUrl = '/api/v1/broadcast/config/';
const getBroadCastHighlights = '/api/v1/broadcast/highlights/';
const selectedPlatformsUrl = '/api/v1/broadcast/platforms/check/';
const genericProxyRequestUrl = '/genericProxyRequest/';
const fetchBroadCastStatsUrl = '/api/v1/broadcast/platform/stats/';
const v2genricProxyUrl = '/v2/genericProxyRequest/';
const getPathVariablesUrl = '/api/v1/getPathVariables';
const getPlatformMapUrl = '/api/v1/getPlatformMap/customer';
const uploadDocumentsUrl = '/api/v1/upload/attachment';
const getDisplayNameForFlowKeyUrl = '/api/v1/flowKey/';

const createBroadcastApi = new BaseService(createBroadcastUrl);
const createShopifyBroadcastApi = new BaseService(createShopifyBroadcastUrl);
const loadBroadCastApi = new BaseService(loadBroadCastUrl);
const deleteBroadcastApi = new BaseService(deleteBroadcastUrl);
const getBroadCastApi = new BaseService(getBroadCastUrl);
const getBroadCastHighlightsApi = new BaseService(getBroadCastHighlights);
const selectedPlatformsApi = new BaseService(selectedPlatformsUrl);
const genericProxyApi = new BaseService(genericProxyRequestUrl);
const fetchBroadCastStatsApi = new BaseService(fetchBroadCastStatsUrl);
const getPathVariablesApi = new BaseService(getPathVariablesUrl);
const v2GenricProxyApi = new BaseService(v2genricProxyUrl);
const getDisplayNameForFlowKeyApi = new BaseService(getDisplayNameForFlowKeyUrl);
const apiGatewayRequest = new BaseService(API_GATEWAY_URL);
const getPlatformMapApi = new BaseService(getPlatformMapUrl, {
	headers: {
		'Content-Type': 'application/json',
	},
});
const uploadDocumentsApi = new BaseService(uploadDocumentsUrl, {
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
	},
});

export const API = {
	createBroadcast(data) {
		const params = {
			module: 'ENGATI_CONNECT',
		};

		return createBroadcastApi.postRequest(data, serialize(params, '?'));
	},

	createBroadcastV2: (data) => {
		const params = {
			targetApi: 'saveBroadcastV2',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},

	updateBroadcastV2: (data) => {
		const params = {
			targetApi: 'saveBroadcastV2',
		};

		return v2GenricProxyApi.putRequest(data, serialize(params, '?'));
	},

	fetchAccountStatus() {
		const params = {
			targetApi: 'getWhatsAppAccountStatus',
			botRef: '{{botRef}}',
		};

		return v2GenricProxyApi.getRequestWithParams(params);
	},

	getTemplateCategories: () => {
		const params = {
			targetApi: 'getAvailableTemplateCategories',
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},

	getTemplateUseCases: () => {
		const params = {
			targetApi: 'getAllTemplateUseCases',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},

	getTemplateDataForChosenCategory: (params) => v2GenricProxyApi.getRequest(serialize(params, '?')),

	getAccountDetails(provider) {
		const params = {
			targetApi: 'getAccountDetails',
			botRef: '{{botRef}}',
			provider,
		};

		return v2GenricProxyApi.getRequestWithParams(params);
	},

	createShopifyBroadcast(email, data) {
		const params = {
			module: 'ENGATI_CONNECT',
			email,
		};

		return createShopifyBroadcastApi.postRequest(data, serialize(params, '?'));
	},
	fetchBroadCasts(request) {
		const params = {
			page: (request.page || 1) - 1,
			size: request.itemPerPage,
			status: request.status,
			sort: request.status === 'DRAFT' ? 'updatedOn,desc' : 'publishedOn,desc',
		};

		return loadBroadCastApi.getRequest(serialize(params, '?'));
	},
	fetchDraftCampaigns(request, botRef) {
		const params = {
			page: (request.page || 1) - 1,
			size: request.itemPerPage,
			status: request.status,
			sort: 'updatedOn,desc',
			targetApi: 'fetchDraftCampaigns',
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	broadcastHistory(params) {
		return v2GenricProxyApi.getRequestWithParams(params);
	},
	userAnalytics(params) {
		return v2GenricProxyApi.getRequestWithParams(params);
	},
	getContactList(params) {
		return v2GenricProxyApi.getRequest(params);
	},
	deleteBroadcast(data) {
		if (data.status === 'SCHEDULED' && new Date(data.publishedOn) < new Date()) {
			return;
		}
		const params = { broadcastId: data.broadcastId };

		return deleteBroadcastApi.deleteRequest(serialize(params, '?'));
	},
	deleteBroadcastV2(data, params) {
		if (data.status === 'SCHEDULED' && new Date(data.publishedOn) < new Date()) {
			return;
		}

		return v2GenricProxyApi.deleteRequest(serialize(params, '?'));
	},
	getBroadCast(broadcastId) {
		const params = { broadcastId };

		return getBroadCastApi.getRequest(serialize(params, '?'));
	},
	getBroadCastV2(broadcastId) {
		const params = {
			targetApi: 'getBroadcastV2',
			replaceable_params: {
				broadcastId,
			},
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	getSelectedPlatform() {
		return selectedPlatformsApi.getRequest();
	},
	deleteCampaign(campaignId) {
		const params = {
			target_url: 'campaign',
			params: { campaign_id: campaignId },
		};

		return genericProxyApi.deleteRequest(serialize(params, '?'));
	},
	getCampaigns() {
		const params = { target_url: 'campaign' };

		return genericProxyApi.getRequestWithParams(params, '?');
	},
	editCampaign(botTimezone, campaignId, campaignName) {
		const data = {
			bot_timezone: botTimezone,
			target_url: 'campaign',
			campaign_name: campaignName,
			campaign_id: campaignId,
		};

		return genericProxyApi.putRequest(data);
	},
	saveCampaign(botTimezone, campaignName) {
		const data = {
			bot_timezone: botTimezone,
			target_url: 'campaign',
			campaign_name: campaignName,
		};

		return genericProxyApi.postRequest(data);
	},
	addMessage(data) {
		return genericProxyApi.postRequest(data);
	},
	editMessage(data) {
		return genericProxyApi.putRequest(data);
	},
	deleteMessage(campaignMessageId) {
		const params = {
			target_url: 'campaignMessage',
			params: { campaign_message_id: campaignMessageId },
		};

		return genericProxyApi.deleteRequest(serialize(params, '?'));
	},
	getCampaignMessages(data) {
		return genericProxyApi.putRequest(data);
	},
	fetchBroadCastTemplates(botRef, platform) {
		const params = {
			targetApi: 'getApprovedTemplates',
			botRef,
			platform,
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	fetchBroadCastTemplateByName(botRef, platform, templateName) {
		const params = {
			targetApi: 'getApprovedTemplates',
			botRef,
			platform,
			templateName,
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	fetchApprovedTemplatesWithLimit(botRef, platform, limit) {
		const params = {
			targetApi: 'getApprovedTemplates',
			botRef,
			platform,
			limit,
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	getJourneyTemplates() {
		const params = {
			targetApi: 'getJourneyTemplates',
			workflow: 'BROADCAST_TEMPLATES',
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	fetchCachedApprovedTemplates(
		botRef,
		platform,
		status = 'approved',
		updateLastSyncedTimestamp = false,
		whatsAppBusinessId = ''
	) {
		const params = {
			targetApi: 'whatsappCachedTemplates',
			botRef,
			status,
			updateLastSyncedTimestamp,
			apiKey: '{{apiKey}}',
			platform,
			whatsAppBusinessId,
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	getTemplateComponentById(templateId) {
		const params = {
			targetApi: 'getTemplateComponentById',
			templateId: templateId,
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	deleteTemplateCache(botRef) {
		const params = {
			targetApi: 'whatsappCachedTemplates',
			botRef,
		};

		return v2GenricProxyApi.deleteRequest(serialize(params, '?'));
	},
	fetchBroadCastStats: (broadcastId) => fetchBroadCastStatsApi.getRequestWithParams({ broadcastId }),
	getDisplayNameForFlowKey: (flowKey) => {
		const params = {
			flowKey,
		};

		return getDisplayNameForFlowKeyApi.getRequest(serialize(params, '?'));
	},
	getPathVariables() {
		return getPathVariablesApi.getRequest();
	},
	searchUserOnUserId(phoneNumber) {
		const params = {
			targetApi: 'searchUserOnUserId',
			searchString: phoneNumber,
		};

		return v2GenricProxyApi.getRequestWithParams(params);
	},
	validateBroadcast: (data, userId) => {
		const params = {
			userId,
			targetApi: 'validateBroadcast',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	testBroadcastByWrapperAPI: (data, params) => v2GenricProxyApi.postRequest(data, serialize(params, '?')),
	getUsersBySolrQuery: (params) => v2GenricProxyApi.getRequest(serialize(params, '?').replace('+1', '%2B1')),
	getLatestBroadcast: (params) => v2GenricProxyApi.getRequestWithParams(params),
	getFileSize(url) {
		return new BaseService('/api/v1/getFileSize').getRequest(serialize({ url }, '?'));
	},
	getPlatformMap() {
		return getPlatformMapApi.getRequest();
	},
	getSegmentsforBot() {
		const params = {
			target_url: 'getAllSegmentsForBot',
		};

		return new BaseService(genericProxyRequestUrl).getRequestWithParams(params);
	},
	getWebUserCount(solrQuery) {
		const params = {
			targetApi: 'getWebUserCountForBroadcast',
			solrQuery,
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	retryBroadcast(data) {
		const params = {
			targetApi: 'retryBroadcast',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	retryBroadcastForV2(data) {
		const params = {
			targetApi: 'retryBroadcastForV2',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},

	stopAutoRetryBroadcastForV2(data, params) {
		return apiGatewayRequest.postRequest(data, serialize(params, '?'));
	},

	stopBroadcast(data, params) {
		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},

	resumeBroadcast(data, params) {
		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},

	disclaimerMail(data) {
		const params = {
			targetApi: 'disclaimerEmail',
		};
		return apiGatewayRequest.postRequest(data, serialize(params, '?'));
	},

	getRetryBroadCastDetails(params) {
		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},

	getExportHistoryForCampaign(size, page, sort, campaignId) {
		const params = {
			page,
			size,
			sort,
			targetApi: 'getCampaignExportHistory',
			replaceable_params: {
				campaignId,
			},
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	getBroadcastHistory(params) {
		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	getChannelsUserCount(solrQuery, platforms) {
		const params = {
			targetApi: 'getChannelsUserCountForBroadcast',
			solrQuery,
			platforms: platforms.join(),
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?').replace('+1', '%2B1'));
	},
	getUserCountForRecurringBroadcast(subscribedRule, platforms) {
		const params = {
			targetApi: 'getUserCountForRecurringBroadcast',
			subscribedRule,
			platforms: platforms.join(),
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?').replace('+1', '%2B1'));
	},
	getBroadCastDetails(params) {
		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	getAutoRetryAnalytics(broadcastId) {
		const params = {
			targetApi: 'analyticsPerRetry',
			replaceable_params: {
				broadcastId,
			},
		};
		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	getUserAnalyticsDetails(params) {
		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	getFileToken(params) {
		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	getExternalBroadcastStatus(params) {
		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	getBroadCastHighlights() {
		return getBroadCastHighlightsApi.getRequest();
	},
	fetchCampaigns(params) {
		return v2GenricProxyApi.getRequestWithParams(params);
	},
	getCampaignDetails(params) {
		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	exportHistory(params) {
		return v2GenricProxyApi.getRequestWithParams(params);
	},
	getMetaAdUniqueUsers(filter) {
		const params = {
			noOfDays: filter,
			targetApi: 'getMetaAdUniqueUsers',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	getMetaAdNonUniqueUsers(filter) {
		const params = {
			noOfDays: filter,
			targetApi: 'getMetaAdNonUniqueUsers',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	getEngagedNonUniqueUsers(filter) {
		const params = {
			noOfDays: filter,
			targetApi: 'getEngagedNonUniqueUsers',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	getAdLevelAnalytics(params) {
		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	retargetForAd(data) {
		const params = {
			targetApi: 'retargetForAd',
			replaceable_params: {
				adId: data,
			},
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	getAllEngagedUsers(filter) {
		const params = {
			noOfDays: filter,
			targetApi: 'getAllEngagedUsers',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	getBroadcastEngagedUsers(filter) {
		const params = {
			noOfDays: filter,
			targetApi: 'getBroadcastEngagedUsers',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	getConversionCriteria() {
		const params = {
			targetApi: 'getActiveConversionCriteria',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	saveConversionCriteria(body) {
		const params = {
			targetApi: 'saveConversionCriteria',
		};

		return apiGatewayRequest.postRequest(body, serialize(params, '?'));
	},
	exportData(data) {
		const params = {
			targetApi: 'exportCampaignData',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	exportAggregatedBroadcastUsersAnalytics(data) {
		const params = {
			targetApi: 'exportAggregatedBroadcastUserAnalytics',
		};

		return apiGatewayRequest.postRequest(data, serialize(params, '?'));
	},
	getMessageTemplateDetails(apiKey, whatsAppBusinessId, platform) {
		const params = {
			status: 'all',
			apiKey: '{{apiKey}}',
			whatsAppBusinessId,
			platform,
			targetApi: 'd360MessageTemplates',
		};

		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	deleteMessageTemplate(templateData, apiKey, whatsAppBusinessId, platform) {
		const params = {
			templateName: templateData.template_name,
			apiKey: '{{apiKey}}',
			whatsAppBusinessId,
			platform,
			targetApi: 'd360MessageTemplates',
		};

		return v2GenricProxyApi.deleteRequest(serialize(params, '?'));
	},
	requestTemplateApproval(body, apiKey, whatsAppBusinessId, whatsAppProviderConfigured) {
		const params = {
			apiKey: '{{apiKey}}',
			whatsAppBusinessId,
			platform: whatsAppProviderConfigured,
			targetApi: 'd360MessageTemplates',
		};

		return v2GenricProxyApi.postRequest(body, serialize(params, '?'));
	},
	createMessageTemplateWithCommonWA(template) {
		const params = {
			targetApi: 'createMessageTemplateWithCommonWA',
		};
		return apiGatewayRequest.postRequest(template, serialize(params, '?'));
	},
	uploadMediaForWhatsappCloud(data) {
		const formData = new FormData();
		formData.append('file', data);

		const params = {
			targetApi: 'uploadMediaForWhatsappCloud',
		};

		return apiGatewayRequest.postRequest(formData, serialize(params, '?'));
	},
	uploadMedia(data) {
		const formData = new FormData();
		formData.append('file', data);

		return new BaseService(`${REACT_APP_API_BASE_URL}/upload`).postRequest(formData);
	},
	uploadDocument(file) {
		const data = new FormData();
		data.append('file', file);
		data.append('workflow', 'SEND_DOCUMENT_ATTACHMENT');

		return uploadDocumentsApi.postRequest(data).then(
			(response) => response,
			() => data
		);
	},
	draftCampaign(data) {
		const params = {
			targetApi: 'draftCampaign',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	deleteDraftCampaign(data) {
		const params = {
			targetApi: 'deleteDraftCampaign',
			replaceable_params: {
				mongo_id: data,
			},
		};

		return v2GenricProxyApi.deleteRequest(serialize(params, '?'));
	},
	saveCampaignV2(data) {
		const params = {
			targetApi: 'saveCampaignV2',
			customerId: '{{customerId}}',
			userId: '{{userId}}',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	retargetOptions(data, params) {
		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	getDashboardTemplateAnalytics(data) {
		const params = {
			targetApi: 'getDashboardTemplateAnalytics',
			...data,
		};

		return v2GenricProxyApi.getRequestWithParams(params);
	},
	getBroadcastColumns() {
		const params = {
			targetApi: 'getBroadcastColumns',
		};

		return v2GenricProxyApi.getRequestWithParams(params);
	},
	// change needed
	exportBroadcastList(data) {
		const params = {
			targetApi: 'exportBroadcastList',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	generateSuggestion(data) {
		const params = {
			targetApi: 'templateSuggestions',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	fetchSuggestion(data) {
		const params = {
			targetApi: 'templateSuggestions',
			redisKey: data,
		};

		return v2GenricProxyApi.getRequestWithParams(params);
	},
	getBotChannelConfig(params) {
		return v2GenricProxyApi.getRequestWithParams(params);
	},
	initiateContactImport(platform, segmentId, timezone) {
		const params = {
			targetApi: 'initiateContactImportForRetargetFlow',
			platform,
			segmentId,
			timezone,
		};

		return v2GenricProxyApi.postRequestWithNoBody(serialize(params, '?'));
	},
	updateTemplateAnalyticsCountFor: (templateId) => {
		const commonParams = {
			templateId,
			targetApi: 'updateOperationTypeCountForTemplate',
		};

		return {
			importCount: () => {
				const params = {
					...commonParams,
					updateOperationType: UpdateOperationType.IMPORT_COUNT,
				};

				return apiGatewayRequest.putRequest(null, serialize(params, '?'));
			},
			submissionCount: () => {
				const params = {
					...commonParams,
					updateOperationType: UpdateOperationType.SUBMISSION_COUNT,
				};

				return apiGatewayRequest.putRequest(null, serialize(params, '?'));
			},
		};
	},
	getEmailTemplates(params) {
		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	saveEmailTemplate(data) {
		const params = {
			targetApi: 'saveEmailTemplate',
		};

		return apiGatewayRequest.postRequest(data, serialize(params, '?'));
	},
	updateEmailTemplate(data) {
		const params = {
			targetApi: 'updateEmailTemplate',
		};

		return apiGatewayRequest.postRequest(data, serialize(params, '?'));
	},
	deleteEmailTemplate(templateId) {
		const params = {
			targetApi: 'deleteEmailTemplate',
			templateId,
		};

		return apiGatewayRequest.deleteRequest(serialize(params, '?'));
	},
	getProjectIdByBotRef(params) {
		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	getLatestBroadcastV2(params) {
		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	getSelectedGoalDashboard: ({ customerId, uid, botRef }) => {
		const params = {
			customerId,
			uid,
			botRef,
			targetApi: 'selectedGoalDashboard',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},

	getDashboardUrl: ({ customerId, uid, dashboardId }) => {
		const params = {
			customerId,
			uid,
			dashboardId,
			targetApi: 'accessibleDashboardUrl',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
};
